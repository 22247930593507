export const webTraderItems = [
    {
        title: "Registration and login",
        text: "To start trading, you need to log into your account by entering the details of the trading account you selected and its password. After entering the required data, log in to the platform.",
        icon: "fi-rr-portrait"
    },
    {
        title: "Interface",
        text: "The main screen shows current stock, bond and other asset quotes in real time. You can also quickly track market changes here.",
        icon: "fi-rr-pulse"
    },
    {
        title: "How to place an order",
        text: "To buy or sell an asset, select it from the list and specify the quantity. Click on \"Buy\" or \"Sell\" to confirm the transaction.",
        icon: "fi-rr-book-alt"
    },
    {
        title: "Risk management",
        text: "You can set up automatic orders to take profits or limit losses. This will protect you from unexpected market movements.",
        icon: "fi-sr-chat-arrow-down"
    },
    {
        title: "Notification settings",
        text: "To stay up-to-date, set up notifications. They will alert you when selected prices are reached or important market events occur.",
        icon: "fi-rr-bell"
    },
    {
        title: "Analytical tools",
        text: "Built-in market analysis tools will help you make more informed decisions by tracking price dynamics and key market indicators.",
        icon: "fi-rr-chart-histogram"
    },
];