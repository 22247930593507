import { FormattedMessage } from "react-intl";
import { Button } from "../../../components/Button/Button";
import { useAppSelector } from "../../../store";
import { selectWTUrl } from "../../../store/payments";
import { webTraderItems } from "./webTraderItems";

export const WebTraderScreen = () => {
    const WTUrl = useAppSelector(selectWTUrl);
    return (
        <>
            <div className="web-trader-header text-card _flex _flex-a-c _flex-j-b _m-b-25">
                <div>
                    <div className="_form-title">WebTrader</div>
                    <div className="_fz-14 _ff-roboto _lh-20">
                        <FormattedMessage id="page.metaTrader_description" />
                    </div>
                </div>
                <Button
                    className="_pre _m-l-20"
                    tag="a"
                    href={WTUrl ? WTUrl : process.env.REACT_APP_BROWSER_WT}
                    rel="nofollow noreferrer"
                    target='_blank'
                >
                    <FormattedMessage id="page.metaTrader_button" defaultMessage="Go to WebTrader"/>
                </Button>
            </div>
            <div className="web-trader-content">
                <div className="row">
                    {webTraderItems.map((item, index) => (
                        <div className="col c-lg-4 c-md-6 _m-b-25" key={item.icon}>
                            <div className="text-card _h-100">
                                <i className={`${item.icon} fi _m-b-15 _flex _fz-24`}/>
                                <div className="_form-title">
                                    <FormattedMessage id={`page.metaTrader_item_title_${index+1}`} defaultMessage={item.title}/>
                                </div>
                                <div className="_fz-14 _ff-roboto _lh-24">
                                    <FormattedMessage id={`page.metaTrader_item_text_${index+1}`} defaultMessage={item.text}/>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}