import { FormattedMessage, useIntl } from "react-intl";
import { useAppSelector } from "../../store";
import { selectPaymentsEnableWebTrader } from "../../store/payments";

import { usePageTitle } from "../../hooks/usePageTitle";
import { WebTraderScreen } from "./WebTraderScreen/WebTraderScreen";
import { MetaTraderScreen } from "./MetaTraderScreen/MetaTraderScreen";
import './MetaTrader.scss';

const MetaTrader = () => {
    const intl = useIntl();
    const ENABLE_WT = useAppSelector(selectPaymentsEnableWebTrader);

    usePageTitle(intl.formatMessage({
        id: "menu.metaTrader",
        defaultMessage: "Trading Platform",
    }));

    return (
        <div className="meta-trader-page">
            <h1><FormattedMessage id="menu.metaTrader" defaultMessage="Trading Platform"/></h1>
            {ENABLE_WT !== null ? ENABLE_WT ? <WebTraderScreen/> : <MetaTraderScreen /> : null}
        </div>
    );
};

export default MetaTrader;
