import { FormattedMessage } from "react-intl";
import metaTrader from "../../../assets/trader/metatrader5.png";
import ios from "../../../assets/trader/ios_new.png";
import android from "../../../assets/trader/android.png";

export const MetaTraderScreen = () => {
    return (
        <div className="meta-trader-content _flex _flex-col _flex-a-c _flex-j-a _text-c _ff-roboto">
            <div>
                <div className="meta-trader-link _m-b-50">
                    <div className="_m-b-20 _lh-20">
                        <FormattedMessage
                            id="page.metaTrader_text_1"
                            defaultMessage="Start using the trading platform by downloading the application from the link:"
                        />
                    </div>
                    <a href={'./' + process.env.REACT_APP_COMPUTER} rel="nofollow noreferrer" download
                       className="trader-img _i-block">
                        <img src={metaTrader} alt="Link to download Trading Platform" width={248}/>
                    </a>
                </div>
                <div className="_m-b-20 _lh-20">
                    <FormattedMessage
                        id="page.metaTrader_text_3"
                        defaultMessage="Download the app for Android or IOS:"
                    />
                </div>
                <div className="_flex _flex-j-c">
                    <a className='ios-link' href={process.env.REACT_APP_IOS} rel="nofollow noreferrer"
                       target='_blank'>
                        <img
                            className='ios-img'
                            src={ios}
                            alt="Link to download IOS Trading Platform"
                            width={128}
                        />
                    </a>
                    <a className='_m-l-20' href={process.env.REACT_APP_ANDROID}
                       rel="nofollow noreferrer" download>
                        <img src={android} width={128}
                             alt="Link to download Android Trading Platform"
                        />
                    </a>
                </div>
            </div>
            <div className="_m-t-50">
                <div className="_m-b-20 _lh-20">
                    <FormattedMessage
                        id="page.metaTrader_text_2"
                        defaultMessage="Or you can start using the trading platform directly from your browser:"
                    />
                </div>
                <a className='_text-italic' href={process.env.REACT_APP_BROWSER} rel="nofollow noreferrer"
                   target='_blank'>
                    {process.env.REACT_APP_BROWSER}
                </a>
            </div>
        </div>
    )
}